<template>
  <div>
    <div class="topimage">
      <el-image style="width: 100%" :src="require('@/assets/energy/banner.jpg')"></el-image>
    </div>
    <div class="appmain">
      <div class="topimg">
        <el-row>
          <el-col :span="12">
            <el-image style="width: 100%" :src="require('@/assets/energy/top1.png')"></el-image>
          </el-col>
          <el-col :span="12">
            <el-image style="width: 100%;cursor: pointer;" :src="require('@/assets/energy/top1_right.png')" @click="nav('product/449')"></el-image>
          </el-col>
        </el-row>
      </div>
      <div class="topimg">
        <el-image style="width: 100%" :src="require('@/assets/energy/top2.png')"></el-image>
      </div>
      <div class="topimg">
        <el-row>
          <el-col :span="12" >
             <el-image style="width: 100%;cursor: pointer;" :src="require('@/assets/energy/top3.png')" @click="nav('product/448')"></el-image>
          </el-col>
           <el-col :span="12">
             <el-image style="width: 100%" :src="require('@/assets/energy/top3_right.png')"></el-image>
          </el-col>
        </el-row>
      </div>
      <div class="topimg">
        <el-row>
          <el-col :span="12">
             <el-image style="width: 100%" :src="require('@/assets/energy/top4.png')"></el-image>
          </el-col>
           <el-col :span="12">
             <el-image style="width: 100%;cursor: pointer;" :src="require('@/assets/energy/top4_right.png')" ></el-image>
          </el-col>
        </el-row>
      </div>
      <div class="topimg">
        <el-row>
          <el-col :span="12">
             <el-image style="width: 100%;cursor: pointer;" :src="require('@/assets/energy/top5.png')" ></el-image>
          </el-col>
           <el-col :span="12">
             <el-image style="width: 100%" :src="require('@/assets/energy/top5_right.png')"></el-image>
          </el-col>
        </el-row>
      </div>
      <div class="topimg">
        <el-row>
          <el-col :span="12">
              <el-image style="width: 100%" :src="require('@/assets/energy/top6.png')"></el-image>
          </el-col>
          <el-col :span="12">
              <el-image style="width: 100%;cursor: pointer;" :src="require('@/assets/energy/top6_right.png')" @click="nav('productinfo?class_id=146&page=1&id=147')"></el-image>
          </el-col>
        </el-row>
        <el-image style="width: 100%" :src="require('@/assets/energy/top7.png')"></el-image>
      </div>
      <div>
        <div class="titles" @click="nav('productinfo?class_id=146&page=1&id=146')">
          相关产品
        </div>
        <div class="jiejue">
          <el-row :gutter="40">
            <el-col :span="6" :xs="24" v-for="(item, index) in productlists" :key="index">
              <div style="
                  cursor: pointer;
                  background: #fafafa;
                  padding-bottom: 30px;
                " @click="nav('product/' + item.product_id)">
                <div style="width: 225px; margin: 0 auto">
                  <el-image style="width: 100%" :src="item.images"></el-image>
                </div>
                <div class="jjtitle">{{ item.product_name }}</div>
                <div style="
                    text-align: center;
                    margin-top: 2%;
                    color: rgb(51, 51, 51, 0.6);
                    font-size: 12px;
                  ">
                  {{ item.product_feature }}
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div>
        <div class="titles" @click="nav('schemeinfo?class_id=5')">解决方案</div>
        <div class="chanpinlist">
          <el-row :gutter="20">
            <el-col :span="8" :xs="12" v-for="(item, index) in schemelists" :key="index">
              <div style="
                  background: #fafafa;
                  padding-bottom: 5%;
                  cursor: pointer;
                  margin-bottom: 5%;
                " @click="nav('scheme/' + item.scheme_id)">
                <div class="chanpinimage">
                  <el-image style="width: 100%" :src="item.image"></el-image>
                </div>
                <div class="chanpintitle">
                  {{ item.scheme_title }}
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { productsList } from "@/api/api";
import { schemeList } from "@/api/api";
export default {
  data() {
    return {
      productlists: [],
      productParams: {
        cat_id: "146",
        page: 1,
        limit: 4,
      },
      schemelists: [],
      schemeParams: {
        class_id: "5",
        page: 1,
        limit: 3,
      },
      bmslist: [
        {
          image: require("@/assets/energy/bms1.png"),
          title: "HBCU100高压控制箱",
          text: "主要功能是采集直流母排电压、电池组总电压和充放电电流，机箱内温度等；<br/>内置BCU模块可实时计算整组电池的SOC，SOH；<br/>可通过上位机设置及保存对电池组的相关保护参数；<br/>通过CAN接口实时接收BMU模块上传的电池电压，温度数据。<br/>",
        },
        {
          image: require("@/assets/energy/bms2.png"),
          title: "HBMU100电池组机箱",
          text: "每个机箱内部由16个电芯串联组成51.2V直流电压的独立模块;<br/>BMU电池管理单元可采集16电芯的电压值和箱内的温度，并通过CAN接口将数据上传到BCU模块。 <br/>",
        },
      ],
      bmsindex: 0,
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#ffffff");
  },
  // 在销毁前，将body的style属性删除
  deactivated() {
    document.querySelector("body").removeAttribute("style");
  },
  created() {
    this.getlist();
  },
  methods: {
    async getlist() {
      const { data: res } = await productsList(this.productParams);
      res.list.forEach((item, index) => {
        item.images =
          process.env.VUE_APP_BASE_API + item.get_image_list[0].product_img;
      });
      this.productlists = res.list;
      const { data: ress } = await schemeList(this.schemeParams);
      ress.list.forEach((item) => {
        item.image = process.env.VUE_APP_BASE_API + item.scheme_image;
      });
      this.schemelists = ress.list;
    },
    nav(url) {
      this.$router.push("/" + url, "_blank");
    },
    previous() {
      let length = this.bmslist.length;
      if (this.bmsindex == 0) {
        this.bmsindex = length - 1;
      } else {
        this.bmsindex = this.bmsindex - 1;
      }
    },
    next() {
      if (this.bmsindex == length) {
        this.bmsindex = 0;
      } else {
        this.bmsindex = this.bmsindex + 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.topimg {
  margin-top: 40px;
}
.titles {
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  color: #333333;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}
.titles:hover {
  color: #0052d9;
}
.jiejue {
  .jjitem {
    cursor: pointer;
    margin-left: 2%;
    padding-bottom: 20px;
    background: #fafafa;
  }
  .jjimage {
    width: 100%;
  }
  .jjtitle {
    color: #333333;
    font-weight: 550;
    text-align: center;
    font-family: OPPOSans-H;
    height: 25px;
  }
}
.chanpinlist {
  .chanpintitle {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.bmspage {
  float: right;
  margin-right: 20px;
  margin-top: 20px;
}
.bmsxian {
  width: 100%;
  height: 1px;
  background: #d8d8d8;
}
.bmsitem {
  margin-top: 80px;
  margin-left: 80px;
  .bmsright {
    font-size: 18px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
  }
  .bmstext {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 34px;
    margin-top: 30px;
  }
}
</style>